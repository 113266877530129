/* Add these styles to the top of the file to ensure no margin/padding on body and html */


.leads2-page {
  background-color: #f3f5fc;
  width: 100%;
  min-height: 100vh; /* Ensures full height of the viewport */
  margin-top: 60px;
  padding: 0;
  font-family: 'Epilogue', sans-serif;
}

.leads2-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.leads2-root-container {
  min-height: 100%; /* Ensure it covers the content height */
  background-color: #f3f5fc; /* Background color for scrolling */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0; /* Remove default margins */
  width: 100%;
  padding-top: 30px;
}

.leads2-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
  padding: 20px;
}

.leads2-sidebar {
  min-width: 250px;
  max-width: 300px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  padding: 15px;
  overflow-y: auto;
  max-height: 85vh;
  position: sticky;
  top: 20px;
}

.leads2-item {
  padding: 12px 15px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  background-color: #f8f9fa;
  border: 1px solid #eee;
}

.leads2-item:hover {
  background-color: #e9f0fe;
  transform: translateY(-1px);
}

.leads2-item.leads2-active {
  background-color: #4285f4;
  color: white;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.leads2-content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.leads2-main-section {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  overflow: hidden;
  width: 100%;
}

.leads2-main-section-title {
  font-size: 22px;
  font-weight: 600;
  padding: 18px 20px;
  margin: 0;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #eee;
  width: 900px;
}

.leads2-main-section-content {
  padding: 20px;
}

.leads2-subsection {
  margin-bottom: 20px;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
}

.leads2-subsection-title {
  font-size: 18px;
  font-weight: 600;
  padding: 14px 16px;
  margin: 0;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #f5f5f7;
}

.leads2-subsection-content {
  padding: 16px;
}

.leads2-brand-section {
  margin-bottom: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
}

.leads2-brand-title {
  font-size: 17px;
  font-weight: 600;
  padding: 14px 16px;
  margin: 0;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #f5f5f7;
}

.leads2-brand-content {
  padding: 16px;
}

.leads2-email-count {
  margin-left: 8px;
  font-size: 14px;
  color: #666;
  font-weight: normal;
}

.leads2-email-placeholder {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 6px;
  margin-top: 10px;
  border-left: 3px solid #4285f4;
}

.leads2-email-subject {
  font-weight: 600;
  margin: 10px 0;
  color: #555;
}

.leads2-email-body {
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.leads2-triangle {
  display: inline-block;
  margin-right: 10px;
  transition: transform 0.3s ease;
  font-size: 12px;
  line-height: 1;
}

.leads2-triangle.leads2-expanded {
  transform: rotate(90deg);
}

.leads2-description {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  white-space: pre-line;
}

.leads2-persona-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  border-left: 3px solid #4285f4;
}

.leads2-persona-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.leads2-persona-details {
  font-size: 14px;
  line-height: 1.6;
  color: #333;
}

.leads2-persona-details p {
  margin-bottom: 4px;
}

.leads2-persona-details strong {
  font-weight: 600;
  color: #555;
}

/* Utility classes */
.leads2-font-medium {
  font-weight: 500;
  color: #444;
}

.leads2-font-semibold {
  font-weight: 600;
}

.leads2-bg-gray-50 {
  background-color: #f9f9f9;
}

.leads2-bg-gray-100 {
  background-color: #f3f4f6;
}

.leads2-p2 {
  padding: 0.5rem;
}

.leads2-p3 {
  padding: 0.75rem;
}

.leads2-p4 {
  padding: 1rem;
}

.leads2-rounded {
  border-radius: 0.25rem;
}

.leads2-mt1 {
  margin-top: 0.25rem;
}

.leads2-mt2 {
  margin-top: 0.5rem;
}

.leads2-mt4 {
  margin-top: 1rem;
}

.leads2-mt8 {
  margin-top: 2rem;
}

.leads2-mb4 {
  margin-bottom: 1rem;
}

.leads2-text-blue-600 {
  color: #2563eb;
}

.leads2-text-red-500 {
  color: #ef4444;
}

.leads2-text-gray-700 {
  color: #374151;
}

.leads2-text-blue-500 {
  color: #3b82f6;
}

.leads2-text-2xl {
  font-size: 1.5rem;
}

.leads2-text-xl {
  font-size: 1.25rem;
}

.leads2-text-sm {
  font-size: 0.875rem;
}

.leads2-text-xs {
  font-size: 0.75rem;
}

.leads2-font-bold {
  font-weight: 700;
}

.leads2-border {
  border: 1px solid;
}

.leads2-border-gray-200 {
  border-color: #e5e7eb;
}

.leads2-border-yellow-400 {
  border-color: #fbbf24;
}

.leads2-bg-yellow-100 {
  background-color: #fef3c7;
}

.leads2-bg-white {
  background-color: #ffffff;
}

.leads2-rounded-lg {
  border-radius: 0.5rem;
}

.leads2-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.leads2-overflow-auto {
  overflow: auto;
}

.leads2-max-h-96 {
  max-height: 24rem;
}

/* Responsive styles */
@media (min-width: 801px) and (max-width: 1350px) {
  .leads2-sidebar {
    min-width: 200px;
    max-width: 250px;
    padding: 10px 15px;
  }

  .leads2-item {
    font-size: 14px;
    padding: 8px;
    margin-bottom: 5px;
  }

  .leads2-container {
    margin-right: 0;
  }
}

@media (max-width: 960px) {
  .leads2-container {
    flex-direction: column;
    align-items: center;
  }

  .leads2-sidebar {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    max-height: 300px;
    position: static;
  }

  .leads2-content-wrapper {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .leads2-container {
    padding: 10px;
  }
  
  .leads2-main-section-title {
    font-size: 18px;
    padding: 14px 16px;
  }
  
  .leads2-subsection-title {
    font-size: 16px;
    padding: 12px 14px;
  }
  
  .leads2-brand-title {
    font-size: 15px;
    padding: 12px 14px;
  }
  
  .leads2-description {
    font-size: 14px;
  }
  
  .leads2-persona-name {
    font-size: 16px;
  }
  
  .leads2-persona-details {
    font-size: 12px;
  }
}

.leads2-brand-subsection {
  margin-bottom: 20px;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
}

.leads2-brand-subsection-title {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 14px;
  margin: 0;
  color: #333;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.leads2-brand-subsection-content {
  padding: 14px;
}

.leads2-brand-subsection-content p strong {
  color: #4285f4;
  font-weight: 600;
}

.leads2-focus-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.leads2-focus-item {
  margin-bottom: 1rem;
}

.leads2-focus-item:last-child {
  margin-bottom: 0;
}
.three-panels-container {
  display: flex;
  height: calc(100vh - 80px); /* Adjust based on your header height */
  gap: 20px;
  padding: 20px;
  background-color: #f3f5fc;
  margin-top: 70px;
  box-sizing: border-box;
  overflow: hidden;
}

.panel {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #e0e0e0;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
}

.panel-title {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #333;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
  display: flex;
  align-items: center;
}

.search-container {
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 8px 12px 8px 32px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #333;
  transition: all 0.2s ease;
  background-color: #ffffff;
}

.search-icon {
  position: absolute;
  left: 10px;
  width: 18px;
  height: 18px;
  color: #999;
  pointer-events: none;
}

.search-input:focus {
  outline: none;
  border-color: #267dff;
  background-color: white;
  box-shadow: 0 0 0 2px rgba(38, 125, 255, 0.1);
}

.search-input::placeholder {
  color: #999;
}

/* Left Panel - Youtubers (15%) */
.youtubers-panel {
  width: 15%;
  min-width: 250px;
}

.youtubers-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.youtuber-item {
  padding: 10px 10px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  border: 0px solid #e0e0e0;
}

.youtuber-item:hover {
  background-color: #f8f9fa;
}

.youtuber-item.active {
  background-color: #f3f3f3;
  color: black;
}

/* Middle Panel - Leads (55%) */
.leads-panel {
  width: 55%;
  min-width: 500px;
}

.leads-content {
  height: 100%;
}

.brands-navigation {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.nav-arrow {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #267dff;
  transition: all 0.2s ease;
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.nav-arrow::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
}

.nav-arrow:first-child::before {
  border-color: transparent #267dff transparent transparent;
  right: 0;
}

.nav-arrow:last-child::before {
  border-color: transparent transparent transparent #267dff;
  left: 0;
}

.nav-arrow:hover:not(:disabled) {
  opacity: 0.8;
}

.nav-arrow:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.brands-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  padding: 0px;
  min-height: 60px;
  flex: 1;
}

.brand-button {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  color: #333;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 80%;
}

.brand-button:hover {
  background-color: #f8f9fa;
  border-color: #267dff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.brand-button.active {
  background-color: #267dff;
  color: white;
  border-color: #267dff;
}

.brand-button:active {
  transform: translateY(0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Scrollbar Styling */
.panel::-webkit-scrollbar {
  width: 8px;
}

.panel::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.panel::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.panel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .three-panels-container {
    flex-direction: column;
    height: auto;
  }

  .panel {
    width: 100% !important;
    min-width: 100% !important;
  }

  .youtubers-panel {
    height: 200px;
  }

  .creator-info-panel {
    height: 400px;
  }

  .leads-panel {
    height: 500px;
  }
}

.three-panels-page {
  min-height: 100vh;
  background-color: #f3f5fc;
  overflow: hidden;
}

.loading, .error, .no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 80px);
  font-size: 1.2rem;
  color: #333;
}

.error {
  color: #dc3545;
}

.brand-section {
  border-bottom: 1px solid #eee;
}

.brand-section:last-child {
  border-bottom: none;
}

.persona-section {
  margin-bottom: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
}

.persona-section h4 {
  margin: 0 0 10px 0;
  color: #267dff;
}

.persona-section p {
  margin: 5px 0;
  color: #666;
}

/* Brand Details Section */
.brand-details {
  margin-top: 20px;
  border-radius: 8px;
}

.brand-details h3 {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 1.2rem;
}

.brand-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.brand-info p {
  margin: 0;
  line-height: 1.5;
}

.brand-info > div {
  margin-bottom: 10px;
}

.brand-info > div > p {
  margin-top: 10px;
}

.brand-info ul {
  margin: 5px 0;
  padding-left: 20px;
}

.brand-info li {
  margin: 5px 0;
  color: #666;
}

.collapsible-section {
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
}

.collapsible-section:last-child {
  margin-bottom: 0;
}

.section-header {
  padding: 12px 15px;
  background-color: #f5f5f7;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #333;
  transition: background-color 0.2s ease;
}

.section-header:hover {
  background-color: #eef0f5;
}

.section-arrow {
  display: inline-block;
  margin-right: 10px;
  transition: transform 0.3s ease;
  font-size: 12px;
  line-height: 1;
}

.section-arrow.expanded {
  transform: rotate(90deg);
}

.section-content {
  padding: 15px;
  background-color: white;
}

/* Creator Info Panel Styles */
.creator-info-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

/* Profile Section */
.profile-section {
  background: white;
  border-radius: 8px;
  padding: 0;
  text-align: center;
}

.profile-image-container {
  width: 120px;
  height: 120px;
  margin: 2px auto 10px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #f0f0f0;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.profile-summary {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.5;
  margin: 0 15px 20px;
}

/* Personas Section */
.personas-section {
  background: white;
  border-radius: 8px;
  padding: 0;
}

.personas-section h3 {
  font-size: 1.1rem;
  color: #333;
  margin: 0;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
}

.persona-item {
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.persona-item:last-child {
  border-bottom: none;
}

.persona-header {
  padding: 12px 15px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.persona-header:hover {
  background: #f8f9fa;
}

.persona-content {
  padding: 15px;
  background: #f8f9fa;
  border-top: 1px solid #f0f0f0;
}

.persona-detail {
  margin-bottom: 12px;
}

.persona-detail:last-child {
  margin-bottom: 0;
}

.persona-detail-label {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 4px;
}

.persona-detail-value {
  font-size: 0.9rem;
  color: #333;
}

.persona-arrow {
  transition: transform 0.2s ease;
}

.persona-arrow.expanded {
  transform: rotate(180deg);
}

.creator-profile-section,
.creator-personas-section {
  background: white;
  border-radius: 8px;
}

.creator-profile-section h3,
.creator-personas-section h3 {
  margin: 0 0 8px 0;
  font-size: 1.1rem;
  color: #333;
  font-weight: 600;
  padding-bottom: 6px;
  border-bottom: 1px solid #eee;
}

.profile-content {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #555;
}

.personas-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 12px;
}

.persona-button {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  color: #333;
  text-align: left;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.persona-button:hover {
  background-color: #f8f9fa;
}

.persona-button .expand-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-size: 18px;
  color: #666;
  background: white;
}

.persona-button .expand-icon.expanded {
  transform: rotate(45deg);
  background: #f8f9fa;
  border-color: #ccc;
}

.persona-details-section {
  margin-top: 0;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out, opacity 0.2s ease-out;
  opacity: 0;
}

.persona-details-section.expanded {
  max-height: 500px;
  opacity: 1;
  transition: max-height 0.3s ease-in, opacity 0.2s ease-in;
}

.persona-card {
  background: #f8f9fa;
  border-radius: 0 0 6px 6px;
  padding: 15px;
  margin-top: -6px;
  border: 1px solid #e0e0e0;
  border-top: none;
}

.persona-card h4 {
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  color: #333;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.persona-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.detail-label {
  font-size: 0.8rem;
  color: #666;
  font-weight: 500;
}

.detail-value {
  font-size: 0.9rem;
  color: #333;
  line-height: 1.3;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
  .personas-grid {
    grid-template-columns: 1fr;
  }
  
  .creator-info-panel {
    width: 30%;
    min-width: 320px;
  }
}

/* Email Card Styles */
.tp-email-card-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.tp-email-card {
  width: 100%;
  border-radius: 8px;
  margin: 0;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.tp-email-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.tp-email-section {
  margin-bottom: 12px;
  font-size: 14px;
  position: relative;
}

.tp-email-section:last-child {
  margin-bottom: 0;
}

.tp-email-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  color: #333;
}

.tp-email-input-container:last-child {
  border-bottom: none;
}

.tp-email-input {
  width: 100%;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  border-bottom: 1px solid #eee;
  padding: 0 0 8px 0;
}

.tp-email-textarea-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.tp-email-textarea {
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
}

.tp-email-textarea p {
  font-size: 14px !important;
  color: #333 !important;
}

.tp-email-textarea p:last-child {
  margin-bottom: 0;
}

.tp-email-textarea ol {
  padding-left: 20px;
  margin: 8px 0;
  list-style-position: outside;
}

.tp-email-textarea ol li {
  margin-bottom: 4px;
  color: #333;
  font-size: 14px;
  line-height: 1.5;
}

.tp-email-textarea ol li:last-child {
  margin-bottom: 0;
}

.tp-email-textarea a {
  color: #4285f4;
  text-decoration: underline;
}

.tp-email-textarea strong {
  font-weight: bold;
}

.tp-email-textarea em {
  font-style: italic;
  
}

.tp-email-textarea h1,
.tp-email-textarea h2,
.tp-email-textarea h3 {
  margin: 10px 0;
  font-weight: bold;
}

.tp-email-buttons {
  width: 100%;
  max-width: 350px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
}

.tp-email-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  border: #c4c4c4 solid 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
}

.tp-email-button:hover {
  color: #595a5b;
  background-color: #ffffff;
}

@media (max-width: 860px) {
  .tp-email-card-wrapper {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .tp-email-card-wrapper {
    min-width: 0px;
    max-width: 450px;
  }
}

@media (max-width: 430px) {
  .tp-email-card-wrapper {
    min-width: 0px;
    max-width: 350px;
  }
}

@media (max-width: 380px) {
  .tp-email-card-wrapper {
    min-width: 0px;
    max-width: 320px;
  }
}

@media (max-width: 350px) {
  .tp-email-card-wrapper {
    min-width: 0px;
    max-width: 300px;
  }
}

/* Right Panel - Creator Info (30%) */
.creator-info-panel {
  width: 30%;
  min-width: 320px;
  overflow-y: auto;
  box-sizing: border-box;
}

/* Contact Details Section */
.contact-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact-detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-label {
  font-weight: 600;
  color: #666;
  min-width: 80px;
}

.contact-value {
  color: #333;
}

.contact-link {
  color: #267dff;
  text-decoration: none;
  font-weight: 500;
}

.contact-link:hover {
  text-decoration: underline;
}

/* Leads Grid Layout */
.leads-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  padding: 0;
}

.brand-card {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  cursor: pointer;
  height: 140px; /* Fixed height */
  justify-content: space-between;
}

.brand-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-color: #267dff;
}

.brand-logo {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.brand-name {
  font-size: 0.85rem;
  font-weight: 400;
  color: #333;
  text-align: center;
  margin: 0;
  line-height: 1.2;
  /* Ensure text doesn't overflow */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 2.4em;
}

.view-button {
  font-size: 0.9rem;
  padding: 2px 14px;
  background: rgb(255, 255, 255);
  border: 1px solid transparent;
  border-radius: 6px;
  color: #000000;
  font-weight: 500;
  margin-top: auto;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .leads-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .leads-grid {
    grid-template-columns: 1fr;
  }
}

.brand-details-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.brand-details-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.back-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #267dff;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 4px 8px;
  border-radius: 600px;
}

.back-arrow {
  line-height: 1;
  display: inline-block;
}

.brand-name-header {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
  color: #333;
}

.brand-content {
  flex: 1;
  background-color: #ffffff;
  border-radius: 8px;
}

.brand-tabs {
  display: flex;
  gap: 8px;
  padding-bottom: 8px;
}

.tab-button {
  background: none;
  border: none;
  padding: 6px 12px;
  font-size: 0.85rem;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 130px;
}

.tab-button.active {
  font-weight: 600;
  border-bottom: 2px solid #333;
}

.tab-content {
  background-color: white;
  border-radius: 8px;
}

.brand-overview {
  height: 100%;
  padding: 12px 0;
}

.outreach-content {
  height: 100%;
}

.brand-overview-section {
  margin-bottom: 20px;
  padding: 0;
}

.brand-overview-section h3 {
  color: #333;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0 0 5px 0;
  padding-bottom: 0px;
}

.brand-overview-section p {
  color: #333;
  line-height: 1.6;
  margin: 0;
  font-size: 0.9rem;
  padding: 0;
}

.brand-overview-section p strong {
  color: #333;
  font-weight: 400;
  display: block;
}

.brand-overview-section p + p {
  margin-top: 10px;
}

.brand-overview-section:last-child {
  margin-bottom: 0;
}

.brand-info-section {
  margin-bottom: 2px;
  background-color: white;
  border-radius: 6px;
  padding: px 0;
}

.brand-info-section:last-child {
  margin-bottom: 0;
}

.brand-info-section h3 {
  color: #333;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 8px 0;
}

.brand-info-section p {
  color: #333;
  line-height: 1.4;
  margin: 0;
  font-size:0.9rem;
}

.brand-info-section p + p {
  margin-top: 8px;
}

.brand-info-section p strong {
  color: #666;
  font-weight: 400;
}

.outreach-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.outreach-content .brand-info-section {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
}

.outreach-content .brand-info-section:last-child {
  margin-bottom: 0;
}

.outreach-content .brand-info-section h3 {
  margin: 0;
  padding: 10px 12px;
  background-color: #f5f5f7;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.95rem;
  font-weight: 600;
  color: #333;
  transition: background-color 0.2s ease;
}

.outreach-content .brand-info-section h3:hover {
  background-color: #eef0f5;
}

/* Move the dropdown arrow to the right side of the header */
.outreach-content .brand-info-section h3::after {
  content: '▼';
  font-size: 12px;
  transition: transform 0.3s ease;
  margin-left: auto; /* Push to the right */
}

.outreach-content .brand-info-section h3.collapsed::after {
  transform: rotate(-90deg);
}

.outreach-content .brand-info-section .section-content {
  padding: 12px;
  max-height: 1000px;
  transition: max-height 0.3s ease-out, opacity 0.2s ease-out;
  opacity: 1;
}

.outreach-content .brand-info-section .section-content.collapsed {
  max-height: 0;
  opacity: 0;
  padding: 0;
  overflow: hidden;
}

.outreach-content .brand-info-section p {
  margin: 0 0 6px;
  font-size: 0.9rem;
  line-height: 1.4;
}

.outreach-content .brand-info-section p:last-child {
  margin-bottom: 0;
}

.outreach-content .brand-info-section strong {
  color: #333;
  font-weight: 600;
  margin-right: 8px;
}

.contact-link {
  color: #267dff;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.contact-link:hover {
  text-decoration: underline;
  opacity: 0.8;
}

.contact-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 8px; /* Add some padding to prevent overlap with dropdown arrow */
}

.contact-navigation {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 4px;
  margin-top: 12px;
}

.contact-nav-button {
  background: none;
  border: none;
  color: #267dff;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  line-height: 1;
}

.contact-nav-button:hover:not(.disabled) {
  background-color: rgba(38, 125, 255, 0.1);
}

.contact-nav-button.disabled {
  color: #ccc;
  cursor: not-allowed;
  opacity: 0.5;
}

.contact-counter {
  font-size: 14px;
  color: #666;
  min-width: 20px;
  text-align: center;
  margin-top: 3px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
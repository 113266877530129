.blog-container {
    padding: 20px;
    text-align: center;
    margin-top: 60px;
  }
  
  .blog-post {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: left;
  }
  
  .blog-post h1 {
    font-size: 2.4rem;
    margin-bottom: 15px;
    font-weight: 700;
    text-align: left;
    line-height: 1.3;
    font-family: 'roboto', sans-serif;
  }
  
  .blog-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: block;
  }
  
  .blog-content {
    font-size: 1rem;
    line-height: 1.8;
    color: #333;
  }
  
  .blog-content p {
    margin-bottom: 15px;
    font-family: 'roboto', sans-serif;
    font-size: 1.1rem;
    color: #333;
  }

  .blog-content ul, .blog-content ol {
    padding-left: 25px;
  }

  .blog-content ol {
    margin-bottom: 15px;
  }
  
  .blog-content li {
    font-family: 'roboto', sans-serif;
    font-size: 1.1rem;
    color: #333;
    line-height: 1.8;
  }

  .blog-content h2 {
    font-family: 'roboto', sans-serif;
  }
  
  @media (max-width: 768px) {
    .blog-post {
      padding: 10px;
    }
  
    .blog-post h1 {
      font-size: 1.7rem;
    }
  
    .blog-content {
      font-size: 0.95rem;
    }
  }
  
.pricing-page {
  background-color: #f3f5fc;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  background-image: url("../public/grid-left.png"),
    url("../public/grid-right.png");
  background-position: left top 16.25rem, right top 8.125rem; /* 260px -> 16.25rem, 130px -> 8.125rem */
  background-repeat: no-repeat;
  background-size: auto 34%; /* Adjusted for relative sizing */
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f3f5fc;

}

.intro-h2 {
  text-align: center;
  max-width: 75rem; /* 1200px -> 75rem */
  margin-top: 11.0rem; /* 200px -> 12.5rem */
  margin-bottom: 1.25rem; /* 20px -> 1.25rem */
  font-size: 2.375rem; /* 38px -> 2.375rem */
  color: #333;
  white-space: pre-line;
  line-height: 1.2;
}

.intro-p {
  text-align: center;
  max-width: 65rem; /* 960px -> 60rem */
  font-size: 1.35rem; /* 22px -> 1.375rem */
  margin: 10px 0px;
  color: #666;
}

.cards-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 118.75rem; /* 1900px -> 118.75rem */
  padding: 1.25rem; /* 20px -> 1.25rem */
  gap: 2.5rem; /* 40px -> 2.5rem */
}

.card {
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
  border: 0.0625rem solid #e2e2e2; /* 1px -> 0.0625rem */
  box-shadow: 0 0.1875rem 0.1875rem rgba(0, 0, 0, 0.1); /* 3px -> 0.1875rem */
  padding: 1.875rem; /* 30px -> 1.875rem */
  border-radius: 0.5rem; /* 8px -> 0.5rem */
  width: 21.875rem; /* 350px -> 21.875rem */
}

.card h2 {
  color: #2e2e2e;
  margin: 0 0 0.9375rem 0; /* 15px -> 0.9375rem */
  text-align: left;
  font-weight: 600;
}

.email-amount {
  color: #2e2e2e;
  font-size: 1.375rem; /* 22px -> 1.375rem */
  text-align: left;
  margin: 0 0 0.75rem 0; /* 12px -> 0.75rem */
  font-weight: 400;
}

.price {
  color: #2e2e2e;
  font-size: 2.375rem; /* 38px -> 2.375rem */
  margin: 0;
  text-align: left;
  font-weight: 600;

}

.buy-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.8125rem 5rem; /* 13px 80px -> 0.8125rem 5rem */
  font-size: 1rem; /* 16px -> 1rem */
  border-radius: 0.625rem; /* 10px -> 0.625rem */
  cursor: pointer;
  margin-top: 0.625rem; /* 10px -> 0.625rem */
  transition: background-color 0.3s;
  font-weight: 700;
}

.buy-button:hover {
  background-color: #1663d7;
}

.user-type-section {
  margin-top: 0;
}

.user-type-label {
  text-align: center;
  font-size: 1.125rem; /* 18px -> 1.125rem */
  margin-top: 1.25rem; /* 20px -> 1.25rem */
}

.toggle-container {
  display: flex;
  border: 0.3125rem solid #ddebff; /* 5px -> 0.3125rem */
  border-radius: 0.625rem; /* 10px -> 0.625rem */
  overflow: hidden;
  background-color: #ddebff;
  font-weight: 600;
}

.toggle-label {
  flex: 1;
  cursor: pointer;
  text-align: center;
  padding: 0.625rem 1.25rem; /* 10px 20px -> 0.625rem 1.25rem */
  background-color: #ddebff;
  transition: background-color 0.3s;
  font-size: 1.125rem; /* 18px -> 1.125rem */
}

input[type="radio"] {
  display: none;
}

input[type="radio"]:checked + .toggle-label {
  background-color: #ffffff;
  color: #333;
}

/* Responsive Styles */

/* Base styles for wide monitors */
@media (min-width: 90rem) { /* 1440px -> 90rem */
  .cards-container {
    max-width: 82.5rem; /* 1320px -> 82.5rem */
    gap: 3rem;
  }
}

/* Tablets and small laptops */
@media (max-width: 75rem) { /* 1200px -> 75rem */
  .cards-container {
    gap: 2.5rem; /* Reduce gap for smaller screens */
    max-width: 70rem; /* Adjust max width */
  }

  .card {
    width: 18.75rem; /* 300px -> 18.75rem */
    padding: 1.25rem; /* 20px -> 1.25rem */
  }
}

/* Tablets */
@media (max-width: 64rem) { /* 1024px -> 64rem */
  .cards-container {
    flex-direction: column;
    align-items: center;
    gap: 1.875rem; /* 30px -> 1.875rem */
  }

  .card {
    width: 80%; /* Full width for tablets */
    padding: 1rem;
  }

  .intro-h2 {
    font-size: 2rem; /* 32px -> 2rem */
  }

  .intro-p {
    font-size: 1.25rem; /* Adjust paragraph size */
  }
}

/* Mobile phones */
@media (max-width: 46.875rem) { /* 750px -> 46.875rem */
  .intro-h2 {
    margin-top: 10rem; /* Add more space for mobile */
    font-size: 1.6rem; /* Adjust heading size */
    padding: 0 5%;

  }

  .intro-p {
    font-size: 1.125rem; /* Adjust paragraph size */
    padding: 0 5%;

  }

  .card {
    width: 90%; /* Full width for mobile */
    padding: 0.875rem;
  }
}

/* Small mobile screens */
@media (max-width: 25rem) { /* 400px -> 25rem */
  .intro-h2 {
    font-size: 1.5rem; /* 24px -> 1.5rem */
    margin-top: 12rem; /* Adjust spacing */
  }

  .intro-p {
    font-size: 1rem; /* 16px -> 1rem */
    padding: 0 5%;
   }

  .card {
    padding: 0.625rem; /* Adjust padding for small screens */
  }

  .card h2 {
    font-size: 1.5rem;
  }
  
  .email-amount {
    font-size: 1.2rem; /* 22px -> 1.375rem */
  }
  
  .price {
    font-size: 1.8rem; /* 38px -> 2.375rem */
  }
  
  .buy-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.8125rem 3rem; /* 13px 80px -> 0.8125rem 5rem */
    font-size: 1rem; /* 16px -> 1rem */
    border-radius: 0.625rem; /* 10px -> 0.625rem */
    cursor: pointer;
    margin-top: 0.625rem; /* 10px -> 0.625rem */
    transition: background-color 0.3s;
    font-weight: 700;
  }
}

@media (min-width: 500px) and (max-width: 800px) and (min-height: 690px) {

  .intro-h2 {
    padding: 0 5%;
    margin-top: 10rem;
  }

  .intro-p {
    padding: 0 5%;
   }


  .card {
    width: 80%; /* Full width for tablets */
  }

}
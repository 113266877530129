.blog-container {
    padding: 20px;
    text-align: center;
  }
  
  
  
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1100px;
    margin: auto;
  }
  
  .blog-item {
    margin-top: 80px;
    background: #ffffff;
    padding: 15px;
    border-radius: 10px;
    text-decoration: none;
    color: white;
    transition: transform 0.3s;
    border: 1px solid #cccccc;
  }
  
  .blog-item img {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #cccccc;
  }
  
  .blog-item h2 {
    font-size: 1.3rem;
    margin: 10px 0;
    color: black;
    line-height: 1.4;
    font-family: "Epilogue", sans-serif;
    padding: 0 1%;
    text-align: left;
    font-weight: 700;
    transition: color 0.3s;
  }
  
  /* Apply hover effect when hovering on the entire .blog-item */
  .blog-item:hover h2 {
    color: #6b41ff;
  }
  
  .blog-item p {
    font-size: 0.9rem;
    color: #333;
    padding: 0 2%;
    text-align: left;
    line-height: 1.3;
  }
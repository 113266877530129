/* Features Section */

.features-section-container {
  text-align: center; /* Centers text (like the heading) inside the container */
  padding: 0 1.25rem; /* 20px -> 1.25rem */
  margin: 0 1.25rem 3.125rem 1.25rem; /* 20px 50px 20px -> 1.25rem 3.125rem 1.25rem */
}

.features-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
  gap: 1.875rem; /* 30px -> 1.875rem */
  width: 100%;
  max-width: 75rem; /* 1200px -> 75rem */
}

.feature-box {
  border-radius: 0.75rem; /* 12px -> 0.75rem */
  padding: 0.0rem; /* 20px -> 1.25rem */
  color: white;
  text-align: center;
  position: relative;
}

/* Styling for icons above h4 */
.feature-icon {
  width: 2.5rem; /* 40px -> 2.5rem */
  height: 2.5rem; /* 40px -> 2.5rem */
}

.time-illustration {
  width: 16.875rem; /* 270px -> 16.875rem */
  height: auto;
  margin-top: 0.625rem; /* 10px -> 0.625rem */
}

.email-illustration {
  width: 16.25rem; /* 260px -> 16.25rem */
  height: auto;
  margin-top: 2.5rem; /* 40px -> 2.5rem */
}

.rocket-illustration {
  width: 12.5rem; /* 200px -> 12.5rem */
  height: auto;
  margin-top: 3.4375rem; /* 55px -> 3.4375rem */
}

/* Individual box colors */
.classification {
  background-color: #267dff;
}

.regression {
  background-color: #00d085;
}

.time-series {
  background-color: #9867ff;
}

.feature-box h4 {
  font-size: 1.5rem; /* 24px -> 1.5rem */
  margin-bottom: 1rem; /* 30px -> 1.875rem */
  margin-top: 0.5rem;
  padding: 0 5%; 
}

.feature-box p {
  font-size: 1rem; /* 16px -> 1rem */
  padding: 0;
  color: white;
  padding: 0 0.25rem; /* 20px -> 1.25rem */
}

.features-heading {
  font-size: 3.125rem; /* 50px -> 3.125rem */
  font-weight: 600;
  margin-bottom: 1.25rem; /* 20px -> 1.25rem */
  color: #333; /* Adjust color as desired */
}

/* Features Section Responsive Styles */

/* Extra large screens */
@media (min-width: 1600px) {
  .features-section {
    max-width: 100rem; /* 1600px -> 100rem */
  }

  .feature-box {
    padding: 6.25rem; /* 100px -> 6.25rem */
  }

  .features-heading {
    font-size: 3.75rem; /* 60px -> 3.75rem */
  }
}

/* General container styling for better alignment */
.features-section-container {
  padding: 0 1.25rem; /* 20px -> 1.25rem */
  margin-top: 10vh;
}

@media (min-width: 1200px) {
  .features-section {
    grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
    gap: 3.125rem; /* 50px -> 3.125rem */
    max-width: 87.5rem; /* 1400px -> 87.5rem */
  }

  .feature-box {
    padding: 1.875rem; /* 30px -> 1.875rem */
  }
}

/* Tablet view */
@media (max-width: 1100px) {
  .time-illustration {
    width: 12.5rem; /* 200px -> 12.5rem */
    height: auto;
    margin-top: 1.25rem; /* 20px -> 1.25rem */
  }

  .email-illustration {
    width: 13.125rem; /* 210px -> 13.125rem */
    height: auto;
    margin-top: 1.875rem; /* 30px -> 1.875rem */
  }

  .rocket-illustration {
    width: 10rem; /* 160px -> 10rem */
    height: auto;
    margin-top: 2.5rem; /* 40px -> 2.5rem */
  }
}

@media (max-width: 930px) {
  .time-illustration {
    width: 11.25rem; /* 180px -> 11.25rem */
    height: auto;
    margin-top: 1.25rem; /* 20px -> 1.25rem */
  }

  .email-illustration {
    width: 11.875rem; /* 190px -> 11.875rem */
    height: auto;
    margin-top: 1.875rem; /* 30px -> 1.875rem */
  }

  .rocket-illustration {
    width: 8.75rem; /* 140px -> 8.75rem */
    height: auto;
    margin-top: 1.25rem; /* 20px -> 1.25rem */
  }
}

/* Mobile view */
@media (max-width: 768px) {
  .features-section {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 0.9375rem; /* 15px -> 0.9375rem */
  }

  .feature-box {
    padding: 0.9375rem; /* 15px -> 0.9375rem */
    margin-bottom: 0.9375rem; /* 15px -> 0.9375rem */
  }

  .features-heading {
    font-size: 2rem; /* 32px -> 2rem */
    margin-bottom: 0.9375rem; /* 15px -> 0.9375rem */
  }

  .feature-icon {
    max-width: 100%;
    height: auto;
    margin-bottom: -0.3125rem; /* -5px -> -0.3125rem */
  }

  .time-illustration, .email-illustration, .rocket-illustration {
    width: 100%; /* Make illustrations responsive to the container */
    height: auto;
    margin-top: 1.25rem; /* 20px -> 1.25rem */
  }
}


@media (max-width: 450px) {

  .features-section-container {
    margin-top: 15vh;
  }

  .features-heading {
    font-weight: 500;
    padding: 0;
    font-size: 29px;
    margin-bottom: 20px;
  }

  .feature-box h4 {
    margin-top: 1rem;
  }

  .time-illustration, .email-illustration, .rocket-illustration {
    width: 70%; /* Make illustrations responsive to the container */
    height: auto;
    margin-top: 1.25rem; /* 20px -> 1.25rem */
  }
}


@media (min-width: 500px) and (max-width: 800px) and (min-height: 690px) {
  .features-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal-width columns */
  gap: 1.875rem; /* 30px -> 1.875rem */
  width: 100%;
  max-width: 75rem; /* 1200px -> 75rem */
  margin: 0 auto; /* Center the grid horizontally within the parent container */
}
}